import * as React from 'react';
import * as Styled from './CTAButton.style';
import { string } from 'prop-types';
import { Link } from 'gatsby';

export interface ICTAButtonProps {
  id?: string;
  text: string;
  link: string;
  primary?: boolean;
}

const CTAButton: React.FC<ICTAButtonProps> = ({ id = '', text, link, primary = false }) => (
  <Styled.Button id={id} primary={primary ? true : false} to={link}>
    {text}
  </Styled.Button>
);

export default CTAButton;
