import * as React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import HomeCallout from '../components/HomeCallout';

const Home: React.FC = () => (
  <Layout>
    <Container>
      <h1>Home</h1>
      <p>
        Integer rutrum nunc sed posuere volutpat. Maecenas quam erat, fringilla ut augue porta, mattis sagittis massa.
        Vestibulum porta cursus dui, sit amet porta augue porta eget. Praesent nec sem fermentum, maximus nibh id,
        blandit libero. Integer molestie urna id velit condimentum vulputate. Sed sed orci eu dolor auctor pretium.
        Phasellus lacinia nisl tortor, id pretium sapien ultrices consectetur. Curabitur rutrum ut lectus et sagittis.
        Sed mattis nunc mattis dictum rutrum.
      </p>
      <p>Praesent molestie eleifend dolor, in interdum mauris ornare at. Nam gravida diam ut leo commodo varius.</p>
      <HomeCallout />
    </Container>
  </Layout>
);

export default Home;
