import React, { useEffect, useState } from 'react';
import CTAButton from '../CTAButton';

import { useOptimizeActivate } from '../../hooks/useOptimizeActivate';
import { useOptimizeCallback } from '../../hooks/useOptimizeCallback';

const HomeCallout: React.FC = () => {
  useOptimizeActivate();
  const [variant] = useOptimizeCallback('xnCbsDpgQIO_v24OeG4dhQ');

  switch (variant) {
    case '1':
      return <CTAButton id="cta_button" link="/about" text="Improve Your Moneys!" />;
    case '0':
    default:
      return <CTAButton id="cta_button" link="/about" text="Sign Up️" />;
  }
};

export default HomeCallout;
