import styled from 'styled-components';
import { Link } from 'gatsby';

export const Button = styled(Link)<{ primary: boolean }>`
  display: inline-block;
  margin-bottom: 20px;
  padding: 20px 30px;
  border-radius: 20px;
  color: ${props => (props.primary ? props.theme.colors.white : props.theme.colors.black)};
  background-color: ${props => (props.primary ? props.theme.colors.primary : props.theme.colors.light)};
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  border: 2px solid ${props => (props.primary ? props.theme.colors.black : 'none')};
  box-shadow: 3px 5px 3px 0px rgba(50, 50, 50, 0.25);
`;
